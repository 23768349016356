import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import {
  SiDiscord,
} from "react-icons/si";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LASS MICH KURZ<span className="purple"> VORSTELLEN </span>
            </h1>
            <p className="home-about-body">
              Seit gut über einem Jahr designe und animiere ich nun Logos rund um Fivem,
              jeden Tag lerne ich dazu, denke ich zumindest 🤷‍♂️
              <br />
              <br />Am meisten aufhalten tue ich mich in
              <i>
                <b className="purple"> Adobe After Effects, Photoshop und Illustrator. </b>
              </i>
              <br />
              <br />
              Mein Ziel ist es jeden Kunden &nbsp;
              <i>
                <b className="purple">zu verstehen </b> und
                das perfekte{" "}
                <b className="purple">
                 Produkt </b>
                auszuliefern
              </i>
              <br />
              <br />
              Immer wenn es für mich möglich ist, lerne ich neue
              with <b className="purple">Methoden</b> rund ums
              <i>
                <b className="purple">
                  {" "}
                  
                  designen und animieren
                </b>
              </i>
              &nbsp;
              <i>
                <b className="purple"> </b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>SCHAU VORBEI</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/kilian0001"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://discord.gg/zldesigns"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <SiDiscord />
                </a>
              </li>
{/*               <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/soumyajit4419/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li> */}
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/kilixn.666/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
