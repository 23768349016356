import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi zusammen, Ich bin <span className="purple">Kilian aka Kilian.01 </span>
            aus <span className="purple"> Bayern, Deutschland.</span>
            <br /> Ich mache seit einigen Monaten Hobbymäßig Logo Designs für Fivem Server (GTAV RP)
            außerdem bin ich beruflich Fachinformatiker für Systemintegration
            <br />
            Meine anderen Hobbys sind das Skifahren und mich mit Freunden in der Stadt zu treffen. Die klassischen Sachen die ein 19 Jähriger eben so macht
            <br />
            <br />
            Andere Hobbys sind
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Videospiele spielen
            </li>
            <li className="about-activity">
              <ImPointRight /> Programmieren
            </li>
            <li className="about-activity">
              <ImPointRight /> Gitarre spielen
            </li>
          </ul>

          <p style={{ color: "#70f07b" }}>
            "Fang an oder es ist irgendwann zu spät!"{" "}
          </p>
          <footer className="blockquote-footer">Kilian</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
