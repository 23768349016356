import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import Hlogo from "../../Assets/Projects/H_logo_showcase.png";
import Dshield from "../../Assets/Projects/Dshield.png";
import Om from "../../Assets/Projects/omlogo.png";
import highlife from "../../Assets/Projects/highlife.gif";
import ExP from "../../Assets/Projects/Komp_1.gif";
import ScarLife from "../../Assets/Projects/scarlife.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Meine letzten <strong className="purple">Werke </strong>
        </h1>
        <p style={{ color: "white" }}>
          Hier findest du ein paar wenige meiner Previews. Weitere auf Discord

        </p>
        <p style={{ color: "white" }}>
        Bitte beachte, dass die Qualität hier aufgrund von performance verschlechtert wurde
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={highlife}
              isBlog={false}
              title="HighLife 3D Logo"
              description=""
              ghLink="https://discord.com/channels/979319024489996298/979319025261752389/1118832686014922783"

            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ScarLife}
              isBlog={false}
              title="ScarLife CL Logo"
              description=""
              ghLink="https://discord.com/channels/979319024489996298/979319025861541918/1130824779952304208"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Om}
              isBlog={false}
              title="OM Logo"
              description=""
              ghLink="https://discord.com/channels/979319024489996298/979319025861541918/1126258658519556177"             
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Hlogo}
              isBlog={false}
              title="H Logo Design"
              description=""
              ghLink="https://discord.com/channels/979319024489996298/979319025861541918/1101445969335291984"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ExP}
              isBlog={false}
              title="ExP Logo"
              description=""
              ghLink="https://discord.com/channels/979319024489996298/979319025261752389/1126640637396123689"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Dshield}
              isBlog={false}
              title="Dream Anticheat Logo"
              description=""
              ghLink="https://discord.com/channels/979319024489996298/979319025861541918/1114299429114085376"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
